<template>
  <div class="my_map" v-loading="isloading">
    <div id="container"></div>
    <!-- <div id="myMap"></div> -->
    <div class="right_panel">
      <div class="searchbox clearfix">
        <div class="searchbox_container">
          <input
            type="text"
            autocomplete="off"
            placeholder="搜地点"
            v-model="search_val"
            class="searchbox_input"
            id="tipinput"
          />
        </div>
        <button
          data-title="搜索"
          data-tooltip="1"
          class="search_button"
          @click.stop="onSearchMap"
        >
          <i class="el-icon-search"></i>
        </button>
      </div>
    </div>
    <div class="btn_fixed">
      <el-button type="primary" @click.native="onBack">完成</el-button>
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
var map, point, geoc, localSearch, geolocation;
export default {
  name: "mapChoice",
  components: {},
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      marker_icon: {
        url: require("@assets/imgs/location_icon.png"),
        size: { width: 32, height: 32 },
      },
      waypoints: ["呼和浩特", { lng: 112.53, lat: 37.87 }, "陕西兵马俑"], //途经点
      from: "",
      to: "",
      isloading: false,
      search_val: "",
      latlng: null,
      // 高德
      MSearch: null, //搜索对象
      gaoDeMap: null, //高德地图对象
      geocoder: null, //高德地图地理编码对象
      city: "广州", //获取用户的城市
      selectAddressInfo: {},
    };
  },
  computed: {
    loc_type: function () {
      return this.$store.getters.loc_type;
    },
    action: function () {
      return this.$store.getters.action;
    },
    point_index: function () {
      return this.$store.getters.point_index;
    },
  },
  created() {
    let { latlng = false } = this.$route.query;
    if (latlng) {
      this.latlng = latlng;
    }
    // console.log("路由经纬度",this.latlng);
  },
  mounted() {
    this.initGaoDeMap();
    // this.initBaiDuMap();
  },
  methods: {
    // 初始化高德地图
    initGaoDeMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "c02c97d41bdd0909efdcaa844373029a",
      };
      AMapLoader.load({
        key: "b64f93db8860c7599fe1a88f8ad4296b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.ControlBar",
          "AMap.HawkEye",
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.AutoComplete",
          "AMap.Icon",
          "AMap.Pixel",
          "AMap.Geocoder",
          "AMap.InfoWindow",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          map = new AMap.Map("container", {
            resizeEnable: true,
            clickable: true,
            zoom: 18, //初始化地图层级
          });
          if (this.latlng) {
            map.setCenter([this.latlng.lng, this.latlng.lat]);
            const marker = new AMap.Marker({
              icon: new AMap.Icon({
                imageOffset: new AMap.Pixel(0, 0),
                image: this.marker_icon.url,
              }),
              position: [this.latlng.lng, this.latlng.lat],
              anchor: "middle-right",
              zIndex: 99,
            });
            map.add(marker);
          }
          this.gaoDeMap = map;
          const scale = new AMap.Scale({
            visible: true,
          });
          const controlBar = new AMap.ControlBar({
            visible: true,
            position: {
              top: "10px",
              left: "10px",
            },
          });
          const toolBar = new AMap.ToolBar({
            visible: true,
            position: {
              top: "110px",
              left: "40px",
            },
          });
          map.addControl(scale);
          map.addControl(controlBar);
          map.addControl(toolBar);

          const Geolocation = new AMap.Geolocation({
            extensions: "all",
          });
          //   console.log(Geocoder)
          Geolocation.getCurrentPosition((results) => {
            console.log("定位编码", results);
            let {
              latitude = "",
              longitude = "",
              address: { city = "" },
            } = results;
            this.city = city || "广州";
            // if (latitude && longitude) {
            //   console.log(latitude, longitude);
            //   map.setCenter([latitude, longitude]);
            // }
          });

          //   AMap.plugin(["AMap.AutoComplete", "AMap.PlaceSearch"],  ()=> {
          const autoOptions = {
            // 城市，默认全国
            city: this.city,
            // 使用联想输入的input的id
            input: "tipinput",
            outPutDirAuto: true,
          };
          const autocomplete = new AMap.AutoComplete(autoOptions);

          const placeSearch = new AMap.PlaceSearch({
            city: this.city,
            map,
          });
          autocomplete.on("select", (e) => {
            console.log("下拉选择", e);
            map.clearMap();
            map.clearInfoWindow();
            //TODO 针对选中的poi实现自己的功能
            const { poi, type } = e;
            placeSearch.search(poi.name);
            this.gaoDeGetAddress({ lnglat: poi.location, type, address: poi });
          });
          this.MSearch = placeSearch;
          //   });

          this.geocoder = new AMap.Geocoder();

          map.on("click", (ev) => {
            map.clearMap();
            map.clearInfoWindow();
            const lnglat = ev.lnglat;
            console.log("点击事件", ev);
            // 设置marker
            const marker = new AMap.Marker({
              icon: new AMap.Icon({
                imageOffset: new AMap.Pixel(0, 0),
                image: this.marker_icon.url,
              }),
              position: [lnglat.lng, lnglat.lat],
              anchor: "middle-right",
              zIndex: 99,
            });
            map.add(marker);
            map.setFitView();
            console.log(lnglat);

            marker.on("click", (res) => {
              console.log("marker点击", res);
            });

            this.gaoDeGetAddress({ lnglat, infoWindow: true });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /**
     * 经纬度转化
     * @param lnglat 经纬度
     * @param infoWindow 自定义窗口信息
     * @param type 事件类型
     * @param address 提供过来混合的数据
     */

    gaoDeGetAddress({
      lnglat,
      infoWindow = false,
      type = "click",
      address = {},
    }) {
      console.log("获取pois信息", address);
      this.geocoder.getAddress([lnglat.lng, lnglat.lat], (status, result) => {
        if (status === "complete" && result.info === "OK") {
          // result为对应的地理位置详细信息

          let {
            regeocode: {
              addressComponent: {
                province,
                city,
                district,
                street,
                streetNumber,
              },
              formattedAddress = "",
              addressComponent,
            },
          } = result;
          let selectAddressInfo = {
            latitude: lnglat.lat,
            longitude: lnglat.lng,
            name: formattedAddress,
            address: `${province}${city}${district}${street}${streetNumber}`,
            province,
            city,
            district,
            linkman_name: "",
            linkman_phone: "",
            address_component: addressComponent,
          };
          // 下拉选择使用提供的提示信息展示更人性化的信息
          if (Object.keys(address).length > 0) {
            selectAddressInfo.name = address.name || formattedAddress;
            selectAddressInfo.address =
              address.address || selectAddressInfo.address;
          }
          if (this.loc_type == "start") {
            this.$store.commit("SET_STARTLOCATION", selectAddressInfo);
          } else if (this.loc_type == "end") {
            this.$store.commit("SET_ENDLOCATION", selectAddressInfo);
          } else {
            if (this.action == 1) {
              let startWayPoint = this.$store.getters.startWayPoint;
              let newStartWay = {
                ...selectAddressInfo,
                action: 1,
              };
              startWayPoint[this.point_index] = newStartWay;
              this.$bus.emit("set_startPoint", { data: newStartWay });
              this.$bus.emit("once");
              this.$store.commit("SET_STARTWAYPOINT", startWayPoint);
            } else {
              let endWayPoint = this.$store.getters.endWayPoint;
              let newendWay = {
                ...selectAddressInfo,
                action: -1,
              };
              this.$bus.emit("set_endPoint", { data: newendWay });
              this.$bus.emit("once");
              this.$store.commit("SET_ENDWAYPOING", endWayPoint);
            }
          }
          if (infoWindow) {
            const infoWindow = new AMap.InfoWindow({
              content: `<div style=\"padding:7px 0px 0px 0px;\"><div>${formattedAddress}</div>`, //使用默认信息窗体框样式，显示信息内容
            });
            infoWindow.open(map, [lnglat.lng, lnglat.lat]);
          }
        }
        console.log("经纬度转化", status, result);
      });
    },
    // 初始化百度地图
    initBaiDuMap() {
      let that = this;
      this.$nextTick((_) => {
        map = new BMap.Map("myMap", {
          renderOptions: {
            map: map,
            autoViewport: true, //自动结果标注
            selectFirstResult: true, //不指定到第一个目标
          },
        });
        point = new BMap.Point(); //当前坐标经纬可改
        geoc = new BMap.Geocoder();
        geolocation = new BMap.Geolocation();
        localSearch = new BMap.LocalSearch(map, {
          renderOptions: {
            map: map,
            autoViewport: false, //自动结果标注
            selectFirstResult: true, //不指定到第一个目标
          },
          pageCapacity: 10, // 每页显示的数量
          // onMarkersSet:function(pois){//自定义搜索回调数据
          //     console.log("自定义搜索回调数据",pois)
          // },
          onSearchComplete: function (results) {
            console.log("接收数据的回调函数", results);
            if (localSearch.getStatus() == BMAP_STATUS_SUCCESS) {
              // 清空地图及搜索列表
              map.clearOverlays();
              //结果列表
              // pois(results);
            }
          },
          // 接收数据的回调函数
        });
        console.log("localSearch", localSearch);
        // this.isloading = true;
        map.centerAndZoom("广州", 18);
        map.setCenter(point);
        map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放

        map.addControl(new BMap.NavigationControl()); // 添加平移缩放控件
        map.addControl(new BMap.ScaleControl()); // 添加比例尺控件
        map.addControl(new BMap.OverviewMapControl()); // 添加缩略地图控件

        localSearch.enableAutoViewport(); //允许自动调节窗体大小

        if (this.latlng != null) {
          console.log("this.latlng", this.latlng);
          let _point = new BMap.Point(this.latlng.lng, this.latlng.lat);
          let _marker = new BMap.Marker(_point); // 创建标注
          console.log("_marker", _marker);
          map.setCenter(_point);
          // map.addOverlay(_marker);                     // 将标注添加到地图中
          // map.panTo(_point);
        }

        // geolocation.enableSDKLocation();
        // geolocation.getCurrentPosition(function(r){
        //     if(this.getStatus() == BMAP_STATUS_SUCCESS){
        //         console.log("rrrr====",r.point)
        //         var mk = new BMap.Marker(r.point);
        //         map.addOverlay(mk);
        //         // 定位到当前地点
        //         map.panTo(r.point);
        //         that.isloading = false;
        //         // r里面包括了一些详细的地址信息和经度纬度的值还有这里需要的point属性，里面也含有经度纬度的值，所以直接调用r.point获取到当前位置
        //     }
        //     else {
        //         console.log('fail',this.getStatus())
        //     }
        // },{enableHighAccuracy: true})

        map.addEventListener("click", (e) => {
          //通过点击百度地图，可以获取到对应的point, 由point的lng、lat属性就可以获取对应的经度纬度
          map.clearOverlays(); // 清除地图上所有的覆盖物
          var pt = e.point;
          var mk = new BMap.Marker(e.point);
          map.addOverlay(mk);
          // 定位到当前地点
          map.panTo(e.point);
          geoc.getLocation(pt, function (rs) {
            console.log("点击定位", rs);
            //addressComponents对象可以获取到详细的地址信息
            var addComp = rs.addressComponents;
            var site =
              addComp.province +
              ", " +
              addComp.city +
              ", " +
              addComp.district +
              ", " +
              addComp.street +
              ", " +
              addComp.streetNumber;
            if (that.loc_type == "start") {
              that.$store.commit("SET_STARTLOCATION", {
                latitude: rs.point.lat,
                longitude: rs.point.lng,
                name: rs.address,
                address: rs.address,
                province: rs.addressComponents.province,
                city: rs.addressComponents.city,
                district: rs.addressComponents.district,
                linkman_name: "",
                linkman_phone: "",
                address_component: rs.addressComponents,
              });
            } else if (that.loc_type == "end") {
              that.$store.commit("SET_ENDLOCATION", {
                latitude: rs.point.lat,
                longitude: rs.point.lng,
                name: rs.address,
                address: rs.address,
                province: rs.addressComponents.province,
                city: rs.addressComponents.city,
                district: rs.addressComponents.district,
                linkman_name: "",
                linkman_phone: "",
                address_component: rs.addressComponents,
              });
            } else {
              if (that.action == 1) {
                let startWayPoint = that.$store.getters.startWayPoint;
                let _data = {
                  latitude: rs.point.lat,
                  longitude: rs.point.lng,
                  name: rs.address,
                  address: rs.address,
                  province: rs.addressComponents.province,
                  city: rs.addressComponents.city,
                  district: rs.addressComponents.district,
                  action: 1,
                  linkman_name: "",
                  linkman_phone: "",
                  address_component: rs.addressComponents,
                };
                startWayPoint[that.point_index] = _data;
                that.$bus.emit("set_startPoint", { data: _data });
                that.$bus.emit("once");
                that.$store.commit("SET_STARTWAYPOINT", startWayPoint);
              } else {
                let endWayPoint = that.$store.getters.endWayPoint;
                let _data = {
                  latitude: rs.point.lat,
                  longitude: rs.point.lng,
                  name: rs.address,
                  address: rs.address,
                  province: rs.addressComponents.province,
                  city: rs.addressComponents.city,
                  district: rs.addressComponents.district,
                  action: -1,
                  linkman_name: "",
                  linkman_phone: "",
                  address_component: rs.addressComponents,
                };
                endWayPoint[that.point_index] = _data;
                that.$bus.emit("set_endPoint", { data: _data });
                that.$bus.emit("once");
                that.$store.commit("SET_ENDWAYPOING", endWayPoint);
              }
            }
          });
        });
      });
    },
    onSearchMap() {
      let search_val = this.search_val;
      if (search_val == "") {
        return false;
      }
      console.log(search_val);

      //   localSearch.search(search_val);
      this.gaoDeMap.clearMap();
      this.gaoDeMap.clearInfoWindow();
      this.MSearch.search(search_val, (status, result) => {
        let pois = result.poiList.pois || [];
        console.log("搜索结果", status, result);
        if (pois.length == 0) {
          this.$message({
            showClose: true,
            message: "暂无数据",
            type: "warning",
          });
        } else {
          this.gaoDeMap.clearMap();
          let markers = [];
          let index = 1;
          for (const item of pois) {
            index++;
            const marker = new AMap.Marker({
              icon: new AMap.Icon({
                imageOffset: new AMap.Pixel(0, 0),
                image: this.marker_icon.url,
              }),
              position: [item.location.lng, item.location.lat],
              anchor: "middle-right",
              title: item.name,
              zIndex: index,
              label: {
                content: item.name,
                direction: "top",
              },
            });
            marker.on("click", (res) => {
              console.log("marker点击", res);
              this.gaoDeGetAddress({
                lnglat: res.lnglat,
                infoWindow: true,
                address: item,
              });
            });
            markers.push(marker);
            this.gaoDeMap.add(markers);
            // this.gaoDeMap.setCenter([item.location.lng, item.location.lat]);
            this.gaoDeMap.setFitView();
          }
        }
      });
    },
    onBack() {
      this.$router.back(-1);
    },
  },
};
</script>
<style lang="scss">
.my_map {
  width: 100%;
  height: 100%;
  position: relative;
  #myMap {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .right_panel {
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    .searchbox {
      border-radius: 2px;
      width: 425px;
      position: relative;
      z-index: 5;
    }
    .clearfix {
      zoom: 1;
    }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .searchbox_input {
      box-sizing: border-box;
      border: 0;
      padding: 9px 0;
      border-left: 10px solid transparent;
      border-right: 27px solid transparent;
      line-height: 20px;
      font-size: 14px;
      height: 38px;
      color: #333;
      position: relative;
      border-radius: 2px 0 0 2px;
      float: left;
      width: 329px;
      outline: 0;
    }
    .search_button {
      pointer-events: auto;
      background: #3385ff;
      width: 57px;
      height: 38px;
      float: left;
      border: 0;
      padding: 0;
      cursor: pointer;
      border-radius: 0 2px 2px 0;
      .el-icon-search {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }
    .search_button:hover {
      background-color: #2e77e5;
    }
  }

  .btn_fixed {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>